import store from '@/store';
import {
  VuexModule,
  Module,
  getModule,
  Action,
  Mutation,
} from 'vuex-module-decorators';
import { v4 as uuidv4 } from 'uuid';
import { getConfigEnv, jsonParse } from '@/utils/helpers';
import userResponsesApolloClient from '@/lib/appsync/workflow';
import updateUserResponse from '@/graphql/userResponses/mutations/updateUserResponse';
import postJobResponseV2 from '@/graphql/userResponses/mutations/postJobResponseV2';
import postComment from '@/graphql/workflow/mutations/postComment.graphql';
import postGlobalWellComment from '@/graphql/workflow/mutations/postGlobalWellComment.graphql';
import postJobComment from '@/graphql/workflow/mutations/postJobComment.graphql';

import TasqResponse from '@/interfaces/tasqs/TasqResponse';
import { mapTasqResponse } from '@/utils/tasqResponses';


import workflowModule from '@/store/modules/workflowModule';
import accountModule from '@/store/modules/accountModule';
import workflowApolloClient from '@/lib/appsync/workflow';
import tasqsListModule from '@/store/modules/tasqsListModule';
import proceduresModule from '@/store/modules/proceduresModule';
import tasqFeedbackModule from '@/store/modules/tasqFeedbackModule';

@Module({
  dynamic: true,
  namespaced: true,
  name: 'tasqFeedbackModule',
  store,
})
class TasqFeedbackModule extends VuexModule {
  	availableSignals = getConfigEnv('SIGNALS_ARRAY');

  	currentSignals: any = [];

	stepper = 1

	// Validate
	selectionAction: any = '';
	// Systems
	systemsSelected: string[] = []
	// Symptoms
	symptomsSelected: string[] = []
	// Causes
	causesSelected: string[] = []
	// Actions
	actionsSelected: string[] = []


	systemOptionsAvailable = []
	symptomOptionsAvailable = []
	causeOptionsAvailable = []
	actionOptionsAvailable = []

	validateComment: string = ''
	systemsComment: string = ''
	symptomsComment: string = ''
	causesComment: string = ''
	actionsComment: string = ''


	@Action
	resetAllData() {
		this.setStepper(1)
		this.resetFeedbackSteps()
		this.setSystemsSelected([])
		this.setSymptomsSelected([])
		this.setCausesSelected([])
		this.setActionsSelected([])
		this.setSelectionAction('')
		this.setValidateComment('')
		this.setSystemsComment('')
		this.setSymptomsComment('')
		this.setCausesComment('')
		this.setActionsComment('')
		this.setSystemOptionsAvailable([])
		this.setSymptomOptionsAvailable([])
		this.setCauseOptionsAvailable([])
		this.setActionOptionsAvailable([])
	}

	@Mutation
	pushSystemOptionsAvailable(data) {
		// @ts-ignore
		this.systemOptionsAvailable.push(data)
	}

	@Mutation
	pushSymptomOptionsAvailable(data) {
		// @ts-ignore
		this.symptomOptionsAvailable.push(data)
	}

	@Mutation
	pushCauseOptionsAvailable(data) {
		// @ts-ignore
		this.causeOptionsAvailable.push(data)
	}

	@Mutation
	pushActionOptionsAvailable(data) {
		// @ts-ignore
		this.actionOptionsAvailable.push(data)
	}


	@Mutation
	setSystemOptionsAvailable(data) {
		this.systemOptionsAvailable = data
	}

	@Mutation
	setSymptomOptionsAvailable(data) {
		this.symptomOptionsAvailable = data
	}

	@Mutation
	setCauseOptionsAvailable(data) {
		this.causeOptionsAvailable = data
	}

	@Mutation
	setActionOptionsAvailable(data) {
		this.actionOptionsAvailable = data
	}






	@Mutation
	setValidateComment(comment) {
		this.validateComment = comment
	}

	@Mutation
	setSystemsComment(comment) {
		this.systemsComment = comment
	}

	@Mutation
	setSymptomsComment(comment) {
		this.symptomsComment = comment
	}

	@Mutation
	setCausesComment(comment) {
		this.causesComment = comment
	}

	@Mutation
	setActionsComment(comment) {
		this.actionsComment = comment
	}






	@Mutation
	pushActionsSelected(data) {
		this.actionsSelected.push(data)
	}

	@Mutation
	removeActionsSelected(index) {
		this.actionsSelected.splice(index, 1);
	}

	@Mutation
	pushCausesSelected(data) {
		this.causesSelected.push(data)
	}

	@Mutation
	removeCausesSelected(index) {
		this.causesSelected.splice(index, 1);
	}


	@Mutation
	removeSymptomsSelected(index) {
		this.symptomsSelected.splice(index, 1);
	}

	@Mutation
	pushSymptomsSelected(data) {
		this.symptomsSelected.push(data)
	}

	@Mutation
	removeSystemsSelected(index) {
		this.systemsSelected.splice(index, 1);
	}

	@Mutation
	pushSystemsSelected(data) {
		this.systemsSelected.push(data)
	}



	@Mutation
	setActionsSelected(data) {
		this.actionsSelected = data
	}

	@Mutation
	setCausesSelected(data) {
		this.causesSelected = data
	}

	@Mutation
	setSymptomsSelected(data) {
		this.symptomsSelected = data
	}

	@Mutation
	setSystemsSelected(data) {
		this.systemsSelected = data
	}

	@Mutation
	setSelectionAction(data) {
		this.selectionAction = data
	}





	@Mutation
	setStepper(step) {
		this.stepper = step
	}


	feedbackSteps = [
		{
		id: 1,
		number: 1,
		name: 'Validate',
		active: true,
		},
		{
		id: 2,
		number: 2,
		name: 'System',
		active: false,
		},
		{
		id: 3,
		number: 3,
		name: 'Symptom',
		active: false,
		},
		{
		id: 4,
		number: 4,
		name: 'Cause',
		active: false,
		},
		{
		id: 5,
		number: 5,
		name: 'Action',
		active: false,
		},
	];


	innerCommentVal = ''
	@Mutation
	setInnerCommentVal(comment) {
		this.innerCommentVal = comment
	}



	@Mutation
	setFeedbackSteps(step) {
		this.feedbackSteps[step - 1].active = true
	}

	@Mutation
	resetFeedbackSteps() {

		this.feedbackSteps = [
			{
			id: 1,
			number: 1,
			name: 'Validate',
			active: false,
			},
			{
			id: 2,
			number: 2,
			name: 'System',
			active: false,
			},
			{
			id: 3,
			number: 3,
			name: 'Symptom',
			active: false,
			},
			{
			id: 4,
			number: 4,
			name: 'Cause',
			active: false,
			},
			{
			id: 5,
			number: 5,
			name: 'Action',
			active: false,
			},
		];
	}


	// @Action
	// resetFeedbackValues() {
	// 	this.resetFeedbackSteps()
	// 	this.setSystemOptionsAvailable([])
	// 	this.setSymptomOptionsAvailable([])
	// 	this.setCauseOptionsAvailable([])
	// 	this.setActionOptionsAvailable([])


	// 	this.setSelectionAction('')
	// 	this.setSystemsSelected([])
	// 	this.setSymptomsSelected([])
	// 	this.setCausesSelected([])
	// 	this.setActionsSelected([])

	// 	workflowModule.setV2ResponseData(null)
	// 	this.setStepper(1)

	// 	this.setSystemsComment('')
	// 	this.setSymptomsComment('')
	// 	this.setCausesComment('')
	// 	this.setActionsComment('')
		
	// }


	@Action
	setupPage(shouldSkipStepperSet = false) {
		
		// Need to set the selected action (may already by happening)
		// But also need to set
		if (!shouldSkipStepperSet) {
			this.resetFeedbackSteps()
		}
		
		this.setSystemOptionsAvailable([])
		this.setSymptomOptionsAvailable([])
		this.setCauseOptionsAvailable([])
		this.setActionOptionsAvailable([])
		if (workflowModule.v2ResponseData != null && workflowModule.v2ResponseData.systems != null) {
			this.setSelectionAction(workflowModule.v2ResponseData.validate)
			this.setSystemsSelected(workflowModule.v2ResponseData.systems)
			this.setSymptomsSelected(workflowModule.v2ResponseData.symptoms)
			this.setCausesSelected(workflowModule.v2ResponseData.causes)
			this.setActionsSelected(workflowModule.v2ResponseData.actions)


			this.setSystemsComment(workflowModule.v2ResponseData.systemsComment == null ? '' : workflowModule.v2ResponseData.systemsComment)
			this.setSymptomsComment(workflowModule.v2ResponseData.symptomsComment == null ? '' : workflowModule.v2ResponseData.symptomsComment)
			this.setCausesComment(workflowModule.v2ResponseData.causesComment == null ? '' : workflowModule.v2ResponseData.causesComment)
			this.setActionsComment(workflowModule.v2ResponseData.actionsComment == null ? '' : workflowModule.v2ResponseData.actionsComment)
			if (!shouldSkipStepperSet && workflowModule.v2ResponseData.validate != null && this.systemsSelected.length > 0 && this.symptomsSelected.length > 0 && this.causesSelected.length > 0) {
				this.setStepper(5)
				this.setFeedbackSteps(4)
				this.setFeedbackSteps(3)
				this.setFeedbackSteps(2)
				this.setFeedbackSteps(1)

			} else if (!shouldSkipStepperSet && workflowModule.v2ResponseData.validate != null && this.systemsSelected.length > 0 && this.symptomsSelected.length > 0) {
				this.setStepper(4)
				this.setFeedbackSteps(4)
				this.setFeedbackSteps(3)
				this.setFeedbackSteps(2)
				this.setFeedbackSteps(1)
				
			} else if (!shouldSkipStepperSet && workflowModule.v2ResponseData.validate != null && this.systemsSelected.length > 0) {
				this.setStepper(3)
				this.setFeedbackSteps(3)
				this.setFeedbackSteps(2)
				this.setFeedbackSteps(1)

			} else if (!shouldSkipStepperSet && workflowModule.v2ResponseData.validate != null) {
				this.setFeedbackSteps(2)
				this.setFeedbackSteps(1)
				this.setStepper(2)
			} else {
				if (!shouldSkipStepperSet) {
					this.setStepper(1)
				}				
			}

		} else {
			if (!shouldSkipStepperSet) {
				this.setStepper(1)
			}
			
		}
		this.setSystemOptionsAvailable([])
		this.setSymptomOptionsAvailable([])
		this.setCauseOptionsAvailable([])
		this.setActionOptionsAvailable([])
	var systemGroupedByTitle = {}
	var symptomsGroupedByTitle = {}
	var causesGroupedByTitle = {}
	var actionsGroupedByTitle = {}
	var selectedSystemIndexes: any[] = []
	for (var x = 0; x < proceduresModule.proceduresStepsV2.length; x++) {
		// Systems
		for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Systems.length; y++) {
			if (this.systemsSelected.includes(proceduresModule.proceduresStepsV2[x].Systems[y].title + "<>" + proceduresModule.proceduresStepsV2[x].Systems[y].text)) {
				selectedSystemIndexes.push(x)
			}
			if (systemGroupedByTitle[proceduresModule.proceduresStepsV2[x].Systems[y].title] == null) {
				systemGroupedByTitle[proceduresModule.proceduresStepsV2[x].Systems[y].title] = [proceduresModule.proceduresStepsV2[x].Systems[y]]
			} else {
				systemGroupedByTitle[proceduresModule.proceduresStepsV2[x].Systems[y].title].push(proceduresModule.proceduresStepsV2[x].Systems[y])
			}
		}
	}

	var selectedSymptomIndexes: any[] = []
	for (var x = 0; x < proceduresModule.proceduresStepsV2.length; x++) {
		// Symptoms
		for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Symptoms.length; y++) {

			if (selectedSystemIndexes.includes(x) || selectedSystemIndexes.length == 0) {
				if (this.symptomsSelected.includes(proceduresModule.proceduresStepsV2[x].Symptoms[y].title + "<>" + proceduresModule.proceduresStepsV2[x].Symptoms[y].text)) {
					selectedSymptomIndexes.push(x)
				}
				if (symptomsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Symptoms[y].title] == null) {
					symptomsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Symptoms[y].title] = [proceduresModule.proceduresStepsV2[x].Symptoms[y]]
				} else {
					symptomsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Symptoms[y].title].push(proceduresModule.proceduresStepsV2[x].Symptoms[y])
				}
			}

		}
	}
		var selectedCauseIndexes: any[] = []

		for (var x = 0; x < proceduresModule.proceduresStepsV2.length; x++) {
			// Causes
			for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Causes.length; y++) {
				if (selectedSymptomIndexes.includes(x) || selectedSymptomIndexes.length == 0) {
					if (this.causesSelected.includes(proceduresModule.proceduresStepsV2[x].Causes[y].title + "<>" + proceduresModule.proceduresStepsV2[x].Causes[y].text)) {
						selectedCauseIndexes.push(x)
					}
					if (causesGroupedByTitle[proceduresModule.proceduresStepsV2[x].Causes[y].title] == null) {
						causesGroupedByTitle[proceduresModule.proceduresStepsV2[x].Causes[y].title] = [proceduresModule.proceduresStepsV2[x].Causes[y]]
					} else {
						causesGroupedByTitle[proceduresModule.proceduresStepsV2[x].Causes[y].title].push(proceduresModule.proceduresStepsV2[x].Causes[y])
					}
				}

			}
		}
		var selectedActionIndexes: any[] = []
		for (var x = 0; x < proceduresModule.proceduresStepsV2.length; x++) {
			// Actions
			for (var y = 0; y < proceduresModule.proceduresStepsV2[x].Actions.length; y++) {
				if (selectedCauseIndexes.includes(x) || selectedCauseIndexes.length == 0) {
					if (this.actionsSelected.includes(proceduresModule.proceduresStepsV2[x].Actions[y].title + "<>" + proceduresModule.proceduresStepsV2[x].Actions[y].text)) {
						selectedActionIndexes.push(x)
					}
					if (actionsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Actions[y].title] == null) {
						actionsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Actions[y].title] = [proceduresModule.proceduresStepsV2[x].Actions[y]]
					} else {
						actionsGroupedByTitle[proceduresModule.proceduresStepsV2[x].Actions[y].title].push(proceduresModule.proceduresStepsV2[x].Actions[y])
					}
				}
			}
		}


	var other: any = null
	for (const [key, value] of Object.entries(systemGroupedByTitle)) {



		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				systems: new_other_array
			}
		} else {
			this.pushSystemOptionsAvailable({
				// @ts-ignore
				title: key,
				// @ts-ignore
				systems: value
			})
		}

	}
	if (other != null) {
		// @ts-ignore
		this.pushSystemOptionsAvailable(other)
	}

	other = null
	for (const [key, value] of Object.entries(symptomsGroupedByTitle)) {
		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				symptoms: new_other_array
			}
		} else {
			this.pushSymptomOptionsAvailable({
				// @ts-ignore
				title: key,
				// @ts-ignore
				symptoms: value
			})
		}
	}
	if (other != null) {
		// @ts-ignore
		this.pushSymptomOptionsAvailable(other)
	}


	other = null
	for (const [key, value] of Object.entries(causesGroupedByTitle)) {
		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				causes: new_other_array
			}
		} else {
			this.pushCauseOptionsAvailable({
				// @ts-ignore
				title: key,
				// @ts-ignore
				causes: value
			})
		}

	}
	if (other != null) {
		// @ts-ignore
		this.pushCauseOptionsAvailable(other)
	}

	other = null
	for (const [key, value] of Object.entries(actionsGroupedByTitle)) {
		if (key == "null" || key == null) {
			var existing_full_values: string[] = []
			var new_other_array: any[] = []
			// @ts-ignore
			for (var y = 0; y < value.length; y++) {
				// @ts-ignore
				if (!existing_full_values.includes(value[y].full_value)) {
					// @ts-ignore
					new_other_array.push(value[y])
					// @ts-ignore
					existing_full_values.push(value[y].full_value)
				}
			}
			other = {
				// @ts-ignore
				title: "Other",
				// @ts-ignore
				actions: new_other_array
			}
		} else {
			this.pushActionOptionsAvailable({
				// @ts-ignore
				title: key,
				// @ts-ignore
				actions: value
			})
		}

	}
	if (other != null) {
		// @ts-ignore
		this.pushActionOptionsAvailable(other)
	}

	}



  @Mutation
  resetSignals(): void {
    this.currentSignals = [];
  }

  @Mutation
  addSignal(signal): void {
    this.currentSignals.push(signal);
  }

 

  @Action
  async leaveFeedbackV2({
    tasq,
    validate = '',
	validateComment,
	systems,
	systemsComment,
	symptoms,
	symptomsComment,
	causes,
	causesComment,
	actions,
	actionsComment,
	proceduresJSONVersion,
    markAsComplete = false,
	completedForToday = false,
	shouldPostAsGlobalWellComment = false
  }) {

    try {
      // const startTime = new Date().getTime();
      let commentId = '';

	  var input = {
		WorkflowTaskID: tasq.workflowTasqId,
		PredictionID: tasq.id,
		CloseTasq: markAsComplete,
		Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		Username: accountModule.user.email,
		Validate: validate,
		ValidateComment: validateComment,
		Systems: systems,
		SystemsComment: systemsComment,
		Symptoms: symptoms,
		SymptomsComment: symptomsComment,
		Causes: causes,
		CausesComment: causesComment,
		Actions: actions,
		ActionsComment: actionsComment,
		ProceduresJSONVersion: proceduresJSONVersion,

	  }

	  if (completedForToday) {
		  // @ts-ignore
		input.CompletedForToday = true
	  }
	  if (tasq.wellTestData && tasq.wellTestData.taskID != null && tasq.wellTestData.ganttChartID != null) {
		  // @ts-ignore
		input.GanttChartID = tasq.wellTestData.ganttChartID
		// @ts-ignore
		input.TaskID = tasq.wellTestData.taskID
	  }
	  if (tasqsListModule.isBatchResponding) {
			for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
				input.PredictionID = tasqsListModule.checkedTasqs[s]
				var fullTasq = tasqsListModule.tasqById(tasqsListModule.checkedTasqs[s])
				input.WorkflowTaskID = fullTasq!.workflowTasqId
				await userResponsesApolloClient.mutate({
					mutation: postJobResponseV2,
					variables: {
					input: input,
					},
				});
			}

		} else {
			await userResponsesApolloClient.mutate({
				mutation: postJobResponseV2,
				variables: {
				  input: input,
				},
			  });
		}

		var responseObj: TasqResponse = {
			id: tasq.id,
			workflowTasqId: tasq.workflowTasqId,
			validate: validate,
			validateComment: validateComment,
			systems: systems,
			systemsComment: systemsComment,
			symptoms: symptoms,
			symptomsComment: symptomsComment,
			causes: causes,
			causesComment: causesComment,
			actions: actions,
			actionsComment: actionsComment,
			jsonVersion: proceduresJSONVersion,
			stepOneCompleted: false,
			stepTwoCompleted: false,
			stepThreeCompleted: false,
			stepFourCompleted: false,
			stepFiveCompleted: false,
		}
		workflowModule.setV2ResponseData(
			responseObj
		)
	  
      if (markAsComplete && commentId && !shouldPostAsGlobalWellComment) {
		if (tasqsListModule.isBatchResponding) {
			for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
				await workflowApolloClient.mutate({
					mutation: postGlobalWellComment,
					variables: {
					  input: {
						NodeID: tasq.wellName,
						Username: accountModule.user.email,
						JobType: tasq.predictionType,
						// @ts-ignore
						Comment: comment,
						Operator: getConfigEnv('OPERATOR_LOWERCASED'),
						ResponseData: input,
						PredictionID: tasqsListModule.checkedTasqs[s],
						CommentID: commentId,
					  },
					},
				  });
			}

		} else {
			await workflowApolloClient.mutate({
				mutation: postGlobalWellComment,
				variables: {
				  input: {
					NodeID: tasq.wellName,
					Username: accountModule.user.email,
					JobType: tasq.predictionType,
					// @ts-ignore
					Comment: comment,
					Operator: getConfigEnv('OPERATOR_LOWERCASED'),
					ResponseData: input,
					PredictionID: tasq.id,
					CommentID: commentId,
				  },
				},
			  });
		}

		if (tasqsListModule.isBatchResponding) {
			for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
				tasqsListModule.updateTasq({
					id: tasqsListModule.checkedTasqs[s],
					data: {
					  completed: true,
					},
				  });
			}

		} else {
			tasqsListModule.updateTasq({
				id: tasq.id,
				data: {
				  completed: true,
				},
			  });
		}

      }


    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }

















  @Action
  async leaveFeedback({
    tasq,
    selection,
    reason = [],
    correctedStateChangeDate = '',
    comment = '',
    actionComment = '',
    procedure = null,
    jobAction = [],
    markAsComplete = false,
    shouldPostJobComment = false,
	completedForToday = false,
	shouldPostAsGlobalWellComment = false
  }) {
    try {
      // const startTime = new Date().getTime();
      let commentId = '';

      if((jobAction.length || actionComment) && (reason.length || comment)) {
        let newComment = '';
        if(reason.length) {
          newComment = `Labels: ${reason.join(', ')},`;
        }

        if(jobAction.length) {
          newComment = `${newComment}  Actions: ${jobAction.join(', ')} -`;
        }
        if(comment) {
          newComment = `${newComment}  Label Remarks: ${comment} -`;
        }
        if(actionComment) {
          newComment = `${newComment}  Action Remarks: ${actionComment}`;
        }

		if (tasqsListModule.isBatchResponding) {
			for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
				
				await workflowApolloClient.mutate({
					mutation: postJobComment,
					variables: {
					  input: {
						prediction_id: tasqsListModule.checkedTasqs[s],
						username: accountModule.user.email,
						comment: newComment,
						should_post_as_global_well_comment: false
					  },
					},
				  });
			}

		} else {
			if (tasqsListModule.isBatchResponding) {
				for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
					await workflowApolloClient.mutate({
						mutation: postJobComment,
						variables: {
						  input: {
							prediction_id: tasqsListModule.checkedTasqs[s],
							username: accountModule.user.email,
							comment: newComment,
							should_post_as_global_well_comment: false
						  },
						},
					  });
				}
			} else {
				await workflowApolloClient.mutate({
					mutation: postJobComment,
					variables: {
					  input: {
						prediction_id: tasq.id,
						username: accountModule.user.email,
						comment: newComment,
						should_post_as_global_well_comment: false
					  },
					},
				  });
			}
		}
      }


      const responseData = {
        // user_id: accountModule.user.email,
        NodeID: tasq.wellName,
		ResponseID: uuidv4(),
        UserResponse: {
          Selection: selection,
          SelectionDetails: {
            Reason: reason,
            // @ts-ignore
            ...jobAction && {
              Action: jobAction,
            },
          },
          ...correctedStateChangeDate && {
            CorrectedStateChangeDate: correctedStateChangeDate,
          },
        },
      };

	  var input = {
		WorkflowTaskID: tasq.workflowTasqId,
		NodeID: tasq.wellName,
		PredictionID: tasq.id,
		ResponseData: {
		  ...responseData,
		  ResponseID: uuidv4(),
		},
		MarkAsComplete: markAsComplete,
		Operator: getConfigEnv('OPERATOR_LOWERCASED'),
		Username: accountModule.user.email
	  }

	  if (completedForToday) {

		  // @ts-ignore
		input.CompletedForToday = true
	  }
	  if (tasq.wellTestData && tasq.wellTestData.taskID != null && tasq.wellTestData.ganttChartID != null) {
		  // @ts-ignore
		input.GanttChartID = tasq.wellTestData.ganttChartID
		// @ts-ignore
		input.TaskID = tasq.wellTestData.taskID
	  }
	  if (tasqsListModule.isBatchResponding) {
			for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
				input.PredictionID = tasqsListModule.checkedTasqs[s]
				var fullTasq = tasqsListModule.tasqById(tasqsListModule.checkedTasqs[s])
				input.WorkflowTaskID = fullTasq!.workflowTasqId
				await userResponsesApolloClient.mutate({
					mutation: updateUserResponse,
					variables: {
					input: input,
					},
				});
			}

		} else {
			await userResponsesApolloClient.mutate({
				mutation: updateUserResponse,
				variables: {
				  input: input,
				},
			  });
		}



	  
      if (markAsComplete && commentId && !shouldPostAsGlobalWellComment) {
		if (tasqsListModule.isBatchResponding) {
			for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
				await workflowApolloClient.mutate({
					mutation: postComment,
					variables: {
					  input: {
						node_id: tasq.wellName,
						username: accountModule.user.email,
						job_type: tasq.predictionType,
						comment,
						operator: getConfigEnv('OPERATOR_LOWERCASED'),
						response_data: responseData,
						prediction_id: tasqsListModule.checkedTasqs[s],
						comment_id: commentId,
					  },
					},
				  });
			}

		} else {
			await workflowApolloClient.mutate({
				mutation: postComment,
				variables: {
				  input: {
					node_id: tasq.wellName,
					username: accountModule.user.email,
					job_type: tasq.predictionType,
					comment,
					operator: getConfigEnv('OPERATOR_LOWERCASED'),
					response_data: responseData,
					prediction_id: tasq.id,
					comment_id: commentId,
				  },
				},
			  });
		}

		if (tasqsListModule.isBatchResponding) {
			for (var s = 0; s < tasqsListModule.checkedTasqs.length; s++) {
				tasqsListModule.updateTasq({
					id: tasqsListModule.checkedTasqs[s],
					data: {
					  completed: true,
					},
				  });
			}

		} else {
			tasqsListModule.updateTasq({
				id: tasq.id,
				data: {
				  completed: true,
				},
			  });
		}

      }


    } catch (e) {
      // showApplicationError('Error while leaving feedback');
      // router.push({ name: 'Tasqs' });
      // eslint-disable-next-line no-console
      console.error(e);
      throw new Error(e);
    }
  }









  @Action
  async postComment({
    tasq,
    selection,
    reason = [],
    correctedStateChangeDate = '',
    comment = '',
    jobAction = [],
	setpointData = null
  }) {
    try {
  

	let commentId = '';


	const responseData = {
		NodeID: tasq.wellName,
		ResponseID: uuidv4(),
		Payload: JSON.stringify(setpointData),
		UserResponse: {
		Selection: selection,
		SelectionDetails: {
			Reason: reason,
			// @ts-ignore
			...jobAction && {
			Action: jobAction,
			},
		},
		...correctedStateChangeDate && {
			CorrectedStateChangeDate: correctedStateChangeDate,
		},
		},
	};



	const {
		data: {
		post_job_comment: {
			comment_id: jobCommentId,
		},
		},
	} = await workflowApolloClient.mutate({
		mutation: postJobComment,
		variables: {
		input: {
			prediction_id: tasq.id,
			username: accountModule.user.email,
			comment,
			response_data: responseData,
			should_post_as_global_well_comment: true,
		},
		},
	});
        // await workflowApolloClient.mutate({
        //   mutation: postComment,
        //   variables: {
        //     input: {
        //       node_id: tasq.wellName,
        //       username: accountModule.user.email,
        //       job_type: tasq.predictionType,
        //       comment,
        //       operator: getConfigEnv('OPERATOR_LOWERCASED'),
        //       response_data: responseData,
        //       prediction_id: tasq.id,
        //       comment_id: commentId,
        //     },
        //   },
        // });
        // tasqsListModule.updateTasq({
        //   id: tasq.id,
        //   data: {
        //     completed: true,
        //   },
        // });

    } catch (e) {
      console.error(e);
      throw new Error(e);
    }
  }




}

export default getModule(TasqFeedbackModule);
